
import {
  ref,
  onMounted,
  getCurrentInstance,
  reactive,
  computed,
  watch
} from 'vue'
import useVuelidate from '@vuelidate/core'
import {
  required,
  helpers
} from '@vuelidate/validators'
import { useStore } from 'vuex'
import {
  voucherDiscountUseCase
} from '@/domain/usecase'
import { useRoute, useRouter } from 'vue-router'
import { encryptDecriptMethods } from '@/plugins/encryptDecriptMethods'
import { environment } from '@/utils'

export default {
  name: '',
  props: {
    isShowDialog: {
      default: false
    },
    voucherCodeId: {
      default: null
    },
    myVoucherId: {
      default: null
    },
    voucherData: {
      default: null
    }
  },
  emits: ['hideDialog', 'rescan'],
  setup(props: any, {
    emit
  }: any) {
    const app = getCurrentInstance()
    const {
      $confirm, $toast, $strInd, $numbers
    } = app!.appContext.config.globalProperties
    const propertiesProps = reactive(props) as any
    const showDialog = propertiesProps.isShowDialog
    const { myVoucherId, voucherCodeId, voucherData } = propertiesProps
    const souvenirCode = ref(null)
    const submitted = ref(false)
    const store = useStore()
    const route = useRoute() as any
    const router = useRouter()
    const dataForm = reactive({
      jenis: voucherData.Nama,
      alamat: ''
    })
    const baseUrl = environment.getApiBase()
    const rules = {
      jenis: {
        required: helpers.withMessage('Jenis Souvenir harus diisi', required)
      },
      alamat: {
        required: helpers.withMessage('Alamat harus diisi', required)
      }
    }
    const v$ = useVuelidate(rules, dataForm)
    const hideDialogPrintRedeem = () => {
      // dialogDetailResi.value = false
      emit('hideDialog')
    }
    const RedeemCode = () => {
      console.log('souvenirCode', souvenirCode)
    }
    const processSubmit = async () => {
      console.log(myVoucherId)
      console.log(voucherCodeId)
      store.dispatch('showLoading')
      const data = {
        MyVoucherId: myVoucherId,
        // VoucherCodeId: voucherCodeId,
        // ClaimTypeName: 'Barang',
        JenisBarang: dataForm.jenis,
        Note: dataForm.alamat
      }
      // console.log('data', data)
      const response = await voucherDiscountUseCase.claimVoucher(data)
      // console.log('res', response)
      submitted.value = false
      if (response.error) {
        $toast.add({
          severity: 'error', detail: response.message, group: 'bc', life: 3000
        })
        store.dispatch('hideLoading')
      } else {
        $toast.add({
          severity: 'success', detail: 'Berhasil print bukti redeem', group: 'bc', life: 5000
        })
        console.log('res', response)
        const redeemId = response.result.Id
        const query = `BuktiKlaimVoucherRetail?IdTransVoucher=${redeemId}`

        // console.log('query', query)
        const routeData = router.resolve({ name: 'report', params: { Id: encryptDecriptMethods.encrypt(query), jenisReport: 'redeem-souvenir' } })
        window.open(routeData.href, '_blank')

        hideDialogPrintRedeem()
      }
      store.dispatch('hideLoading')
    }
    const submitData = async (isFormValid: boolean) => {
      submitted.value = true
      if (!isFormValid) {
        return
      }
      // console.log('aaaa')
      processSubmit()
    }

    const rescan = () => {
      emit('rescan')
    }

    return {
      souvenirCode,
      showDialog,
      hideDialogPrintRedeem,
      RedeemCode,
      dataForm,
      v$,
      submitData,
      submitted,
      rescan,
      baseUrl
    }
  }
}
